import React from "react"

import { useState, useEffect } from "react"

import Slide from "./Slide"

import "../css/sliderSection.css"
import { nanoid } from "nanoid"

export default function SliderSection({ children }) {
  const numberOfSlides = children
    ? children.length && children.length > 1
      ? children.length
      : 1
    : 0

  const [activeSlide, setActiveSlide] = useState(0)

  const [containerTransform, setContainerTransform] = useState(
    "translateX( -" + (activeSlide * 100) / numberOfSlides + "%"
  )

  const setPreviousSlide = () => {
    if (activeSlide - 1 < 0) setActiveSlide(numberOfSlides - 1)
    else setActiveSlide(activeSlide - 1)
  }

  const setNextSlide = () => {
    if (activeSlide + 1 === numberOfSlides) setActiveSlide(0)
    else setActiveSlide(activeSlide + 1)
  }

  useEffect(() => {
    setContainerTransform(
      "translateX( -" + (activeSlide * 100) / numberOfSlides + "%"
    )
  }, [activeSlide, numberOfSlides])

  var content = <></>

  if (children && children.length > 1)
    content = children.map((child, index) => (
      <Slide
        index={index}
        width={100 / numberOfSlides + "%"}
        activeSlide={activeSlide}
        backgroundImage={child.props.backgroundImage}
        className={child.props.className}
        key={nanoid()}
      >
        {child.props.children}
      </Slide>
    ))
  else if (children) content = children

  return (
    <section className="kn-slider-section">
      <div
        style={{
          width: 100 * numberOfSlides + "%",
          transform: containerTransform,
        }}
        className="kn-slides-container"
      >
        {content}
      </div>

      {children && children.length > 1 && (
        <div className="kn-slider-controls">
          <button
            className="kn-prev"
            aria-label="Previous"
            onClick={() => setPreviousSlide()}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <button
            className="kn-next"
            aria-label="Next"
            onClick={() => setNextSlide()}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      )}
    </section>
  )
}
