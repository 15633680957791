import React from "react"

import "../css/slide.css"

export default function Slide({
  index,
  width,
  activeSlide,
  backgroundImage,
  children,
  className,
}) {
  return (
    <div
      style={{ width: width }}
      className={`kn-slide${activeSlide === index ? " kn-active-slide" : ""} ${
        typeof className !== "undefined" ? className : ""
      }`}
    >
      <img className="kn-slide-image" src={backgroundImage} alt="pozadina" />

      <div className="kn-slide-content">{children}</div>
    </div>
  )
}
