import * as React from "react"

import Layout from "../components/Layout"

import Seo from "../components/Seo"

import Section from "../components/Section"

import Slide from "../components/Slide"

import SliderSection from "../components/SliderSection"

import AnimatedHeading from "../components/AnimatedHeading"

import AnimatedBlock from "../components/AnimatedBlock"

import { nanoid } from "nanoid"

import "../css/index.css"

import dataUsluge from "../content/usluge.json"

import slideImage0 from "../images/slika1b.jpg"
import slideImage1 from "../images/slika 2.jpg"
import slideImage2 from "../images/slika3.jpeg"
import slideImage3 from "../images/pozadina.jpg"

const IndexPage = ({ children }) => {
  return (
    <Layout>
      <Seo title="Početna" />

      <SliderSection>
        <Slide backgroundImage={slideImage0}>
          <h2 className="kn-first-section-title">Geodioba d.o.o.</h2>
          <h3 className="kn-first-section-subtitle">
            ovlaštena tvrtka za geodetske usluge
          </h3>
        </Slide>
        <Slide backgroundImage={slideImage1}>
          <h2 className="kn-first-section-title">Geodioba d.o.o.</h2>
          <h3 className="kn-first-section-subtitle">
            ovlaštena tvrtka za geodetske usluge
          </h3>
        </Slide>
        <Slide backgroundImage={slideImage2} className={`kn-slide-dark`}>
          <h2 className="kn-first-section-title kn-title-dark">
            Geodioba d.o.o.
          </h2>
          <h3 className="kn-first-section-subtitle kn-title-dark">
            ovlaštena tvrtka za geodetske usluge
          </h3>
        </Slide>
        <Slide backgroundImage={slideImage3}>
          <h2 className="kn-first-section-title">Geodioba d.o.o.</h2>
          <h3 className="kn-first-section-subtitle">
            ovlaštena tvrtka za geodetske usluge
          </h3>
        </Slide>
      </SliderSection>

      <Section
        className="kn-third-section"
        animation={[
          {
            top: "50%",
            left: "0%",
            transform: "translate( 0%, 0%)",
            width: "100%",
            height: "auto",
            padding: "0",
            opacity: "0",
            transition: " all 0.4s 0.3s ease",
          },
          {
            top: "0%",
            left: "0%",
            transform: "translate( 0%, 0%)",
            padding: "50px 0px",
            opacity: "1",
            transition: "all 0.6s 0.2s ease",
          },
        ]}
        id="Usluge"
      >
        <div className="kn-third-section-title-container">
          <AnimatedHeading
            className="kn-third-section-title-ah"
            delay={1200}
            delayBetween={20}
            duration={1000}
          >
            <h2 className="kn-third-section-title">Naše usluge</h2>
          </AnimatedHeading>
          <AnimatedBlock className="kn-third-section-content-ab" delay={600}>
            {dataUsluge &&
              dataUsluge.map((child, index) => (
                <AnimatedBlock
                  key={nanoid()}
                  className="kn-third-section-content-ab-sub"
                  delay={200 + (index % 3) * 200}
                  animation={[
                    {
                      transform: "translate( 0, 50px)",
                      opacity: "0",
                      transition: "all 0.7s ease",
                    },
                    {
                      transform: "translate( 0, 0px)",
                      opacity: "1",
                      transition: "all 0.7s ease, filter 0.25s ease",
                    },
                  ]}
                >
                  <div className="kn-third-section-content-ab-sub-link">
                    <div className="kn-block-services-icon">
                      <i className={child.fontAwesomeClassIcon}></i>
                    </div>
                    <hr className="kn-block-services-divider" />
                    <h3 className="kn-block-services-title">{child.title}</h3>
                    <hr className="kn-block-services-divider-on-hover" />
                    <p className="kn-block-services-text-on-hover">
                      {child.content.map(element => element.innerHTML)}
                    </p>
                  </div>
                </AnimatedBlock>
              ))}
          </AnimatedBlock>
        </div>
      </Section>

      <Section
        className="kn-second-section"
        animation={[
          {
            top: "50%",
            left: "0%",
            transform: "translate( -20%, -50%)",
            width: "0%",
            height: "auto",
            padding: "0",
            opacity: "0",
            transition: " all 0.8s 0.8s ease",
          },
          {
            top: "50%",
            left: "100px",
            transform: "translate( 0%, -50%)",
            padding: "50px 0px",
            opacity: "1",
            transition: "all 0.8s 0.5s ease",
          },
        ]}
        backgroundImage={slideImage0}
        paralax={0.1}
        id="O-nama"
        darken={true}
      >
        <div className="kn-second-section-title-container">
          <AnimatedHeading
            className="kn-second-section-title-ah"
            delay={1200}
            delayBetween={20}
            duration={1000}
          >
            <h2 className="kn-second-section-title">O Nama</h2>
          </AnimatedHeading>
          <AnimatedBlock
            className="kn-second-section-paragraph-ab"
            delay={1000}
            animation={[
              {
                transform: "translate( 0px, -25px)",
                opacity: "0",
                transition: "all 0.8s ease, opacity 0.5s 0.2s ease",
                padding: "15px 50px",
              },
              {
                transform: "translate( 0, 0px)",
                opacity: "1",
                transition: "all 0.8s ease, opacity 0.5s 0.2s ease",
                padding: "15px 50px",
              },
            ]}
          >
            <hr className="kn-block-services-divider" />
          </AnimatedBlock>
          <AnimatedBlock
            className="kn-second-section-paragraph-ab"
            delay={1000}
            animation={[
              {
                transform: "translate( -50px, 0px)",
                opacity: "0",
                transition: "all 0.8s ease, opacity 0.8s 0.3s ease",
                padding: "30px 50px",
              },
              {
                transform: "translate( 0, 0px)",
                opacity: "1",
                transition: "all 0.8s ease, opacity 0.8s 0.3s ease",
                padding: "30px 50px",
              },
            ]}
          >
            <p className="kn-fourth-section-text">
              GEODIOBA d.o.o. je ovlaštena tvrtka za obavljanje geodetske
              djelatnosti i stručnih geodetskih poslova.
              <br />
              <br />
              Ured tvrtke je u Sisku, Ostrovo 78, Topolovac, a radimo na
              području cijele Hrvatske koristeći najmoderniju opremu pri
              pružanju različitih usluga.
              <br />
              <br />
              Suglasnost Državne geodetske uprave uz članstvo u Hrvatskoj komori
              ovlaštenih inženjera geodezije te višegodišnje iskustvo jamstvo su
              brzo, sigurno i kvalitetno obavljene usluge.
              <br />
              <br />
              <br />
              A sjedište našeg ureda ne predstavlja problem za izvršenje Vaše
              usluge jer sve detalje možete saznati i dogovoriti putem
              telefonskog poziva ili e-maila bez obveze dolaska u ured.
              <br />
              <br />
              Stoga nas besplatno kontaktirajte i saznajte ponudu za Vašu
              uslugu.
              <br />
              <br />
            </p>
          </AnimatedBlock>
        </div>
      </Section>

      <Section
        className="kn-third-section"
        animation={[
          {
            top: "50%",
            left: "0%",
            transform: "translate( 0%, 0%)",
            width: "100%",
            height: "100px",
            padding: "0",
            opacity: "0",
            transition: "none",
          },
          {
            top: "0%",
            left: "0%",
            transform: "translate( 0%, 0%)",
            padding: " 0px",
            height: "100px",
            opacity: "1",
            transition: "none",
          },
        ]}
        id=""
        minHeight={100}
      >
        <></>
      </Section>
      <Section
        className="kn-fourth-section"
        animation={[
          {
            top: "0%",
            right: "0px",
            transform: "translate( 0%, 0%)",
            width: "0%",
            height: "auto",
            padding: "0",
            opacity: "0",
            transition: " all 0.8s 0.8s ease",
          },
          {
            top: "0%",
            right: "100px",
            transform: "translate( 0%, 0%)",
            padding: "0px 0px",
            opacity: "1",
            transition: "all 0.8s 0.5s ease",
          },
        ]}
        iframeSrc={
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3629.3877072251485!2d16.427939659269686!3d45.45689993515152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4766efb6335cc559%3A0xfde1f4def5a0e6d5!2sOstrvo%2078%2C%2044202%2C%20Topolovac!5e0!3m2!1shr!2shr!4v1631550837339!5m2!1shr!2shr"
        }
        paralax={0.1}
        minHeight={400}
        id="Kontakt"
      >
        <div className="kn-fourth-section-title-container">
          <AnimatedHeading
            className="kn-fourth-section-title-ah"
            delay={1200}
            delayBetween={20}
            duration={1000}
            minHeight={500}
          >
            <h2 className="kn-fourth-section-title">Kontakt</h2>
          </AnimatedHeading>

          <AnimatedBlock
            className="kn-fourth-section-paragraph-ab"
            delay={1000}
            animation={[
              {
                transform: "translate( 0px, -25px)",
                opacity: "0",
                transition: "all 0.8s ease, opacity 0.5s 0.2s ease",
                padding: "15px 50px",
              },
              {
                transform: "translate( 0, 0px)",
                opacity: "1",
                transition: "all 0.8s ease, opacity 0.5s 0.2s ease",
                padding: "15px 50px",
              },
            ]}
          >
            <hr className="kn-block-services-divider" />
          </AnimatedBlock>

          <AnimatedBlock
            className="kn-fourth-section-paragraph-ab"
            delay={1100}
            animation={[
              {
                transform: "translate( 50px, 0px)",
                opacity: "0",
                transition: "all 0.8s ease, opacity 0.8s 0.3s ease",
                padding: "30px 50px",
              },
              {
                transform: "translate( 0, 0px)",
                opacity: "1",
                transition: "all 0.8s ease, opacity 0.8s 0.3s ease",
                padding: "30px 50px",
              },
            ]}
          >
            <ul className="kn-kontakt-menu">
              <li>
                <AnimatedHeading
                  className="fas fa-user"
                  delay={150}
                  delayBetween={35}
                >
                  <i></i>
                </AnimatedHeading>
                <AnimatedHeading
                  className="kn-kontakt-user"
                  delay={250}
                  delayBetween={35}
                >
                  <h4>Ivan Josić mag.ing.geod. et geoinf., direktor</h4>
                </AnimatedHeading>
              </li>
              <li>
                <AnimatedHeading
                  className="fas fa-map-marker-alt"
                  delay={250}
                  delayBetween={35}
                >
                  <i aria-hidden="true"></i>
                </AnimatedHeading>
                <AnimatedHeading
                  className="kn-kontakt-link"
                  delay={350}
                  delayBetween={35}
                >
                  <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3629.3877072251485!2d16.427939659269686!3d45.45689993515152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4766efb6335cc559%3A0xfde1f4def5a0e6d5!2sOstrvo%2078%2C%2044202%2C%20Topolovac!5e0!3m2!1shr!2shr!4v1631550837339!5m2!1shr!2shr">
                    Ostrovo 78, Topolovac
                  </a>
                </AnimatedHeading>
              </li>
              <li>
                <AnimatedHeading
                  className="fas fa-envelope"
                  delay={350}
                  delayBetween={35}
                >
                  <i aria-hidden="true"></i>
                </AnimatedHeading>
                <AnimatedHeading
                  className="kn-kontakt-link"
                  delay={450}
                  delayBetween={35}
                >
                  <a href="mailto:ivan@geodioba.hr">ivan@geodioba.hr</a>
                </AnimatedHeading>
              </li>
              <li>
                <AnimatedHeading
                  className="fas fa-mobile-alt"
                  delay={450}
                  delayBetween={35}
                >
                  <i aria-hidden="true"></i>
                </AnimatedHeading>
                <AnimatedHeading
                  className="kn-kontakt-link"
                  delay={550}
                  delayBetween={35}
                >
                  <a href="tel:+385 91 550 58 83">+385 91 550 58 83</a>
                </AnimatedHeading>
              </li>
            </ul>
          </AnimatedBlock>
        </div>
      </Section>
    </Layout>
  )
}

export default IndexPage
