import * as React from "react"
import { useState, useEffect, useLayoutEffect, useRef } from "react"
import PropTypes from "prop-types"
import useOnScreen from "../hooks/useOnScreen"

import "../css/section.css"

const Section = ({
  className,
  id,
  animation,
  backgroundImage,
  iframeSrc,
  animationBackground,
  minHeight,
  paralax,
  children,
  darken,
}) => {
  const [contentPosition, setContentPosition] = useState({ ...animation[0] })
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [imageLoad, setImageLoad] = useState(false)
  const [backgroundStyleObject, setBackgroundStyleObject] = useState({
    ...animationBackground[0],
  })
  const [sectionSeen, setSectionSeen] = useState(false)
  const [scrollOffsetY, setScrollOffsetY] = useState(0)

  const localRef = useRef(null)

  const sectionVisible = useOnScreen(localRef)

  useEffect(() => {
    if (backgroundImage === null && iframeSrc === null) setImageLoad(true)
  }, [backgroundImage, iframeSrc])

  useEffect(() => {
    if (imageLoad && sectionVisible) setSectionSeen(true)
  }, [sectionVisible, imageLoad])

  useEffect(() => {
    if (imageLoad && sectionVisible && !sectionSeen) {
      setWidth("100%")

      if (backgroundImage)
        setHeight(
          localRef.current?.lastElementChild.getBoundingClientRect().height +
            parseInt(
              window
                .getComputedStyle(
                  localRef.current?.lastElementChild.firstElementChild
                )
                .getPropertyValue("padding-bottom")
            ) +
            parseInt(
              window
                .getComputedStyle(
                  localRef.current?.lastElementChild.lastElementChild
                )
                .getPropertyValue("padding-bottom")
            )
        )
      else setHeight("auto")

      setBackgroundStyleObject({ ...animationBackground[1] })
    }
  }, [
    imageLoad,
    sectionVisible,
    backgroundImage,
    animationBackground,
    sectionSeen,
  ])

  useEffect(() => {
    setTimeout(() => {
      if (sectionVisible) {
        setContentPosition({ height: height, ...animation[1] })
      }
    }, 50)
  }, [sectionVisible, height, animation])

  useEffect(() => {
    if (paralax > 0) {
      const func = {
        name: () => {
          //let offset =  - window.pageYOffset * paralax

          if (document.getElementById(id)) {
            let bodyRect = document.body.getBoundingClientRect()

            let elemRect = document.getElementById(id).getBoundingClientRect()

            let offset = elemRect.top - bodyRect.top - window.pageYOffset

            setScrollOffsetY(-offset * paralax)
          }
        },
      }

      window.addEventListener("scroll", func.name)

      return () => window.removeEventListener("scroll", func.name)
    }
  }, [paralax, height, id])

  return (
    <section
      id={id}
      className={`kn-section-wrapper ${className} ${
        darken ? "kn-section-darken" : ""
      }`}
      style={!backgroundImage && iframeSrc ? { maxHeight: minHeight } : {}}
    >
      {backgroundImage && (
        <img
          src={backgroundImage}
          onLoad={() => {
            setImageLoad(true)
          }}
          alt=""
          className="kn-background-image"
          style={{
            transform: `translateY( ${scrollOffsetY}px)`,
            minHeight: `${minHeight}px`,
            transition: `all 50ms ease`,
          }}
        />
      )}
      {!backgroundImage && !iframeSrc && (
        <div
          className="kn-section-background-color"
          style={{
            height: "100%",
            position: "absolute",
            width: "100%",
            ...backgroundStyleObject,
            transform: `translateY( ${scrollOffsetY}px)`,
            minHeight: `${minHeight}px`,
            transition: `all 50ms linear`,
          }}
        ></div>
      )}
      {!backgroundImage && iframeSrc && (
        <iframe
          onLoad={() => {
            setImageLoad(true)
          }}
          title="Google karta"
          className="kn-section-iframe"
          src={iframeSrc}
          style={{
            height: minHeight + "px",
            width: "100%",
            ...backgroundStyleObject,
            transform: `translateY( ${scrollOffsetY}px))`,
            minHeight: `${minHeight}px`,
            transition: `all 50ms linear`,
          }}
        ></iframe>
      )}
      <div
        ref={localRef}
        style={
          !backgroundImage
            ? {
                ...contentPosition,
                position: iframeSrc === null ? "relative" : "absolute",
              }
            : { ...contentPosition }
        }
        className={`kn-section-container ${className}-container`}
      >
        {children}
      </div>
    </section>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  animation: PropTypes.array,
  backgroundImage: PropTypes.string,
  iframeSrc: PropTypes.string,
  animationBackground: PropTypes.array,
  children: PropTypes.node.isRequired,
  minHeight: PropTypes.number,
  paralax: PropTypes.number,
}

Section.defaultProps = {
  className: ``,
  id: ``,
  animation: [
    {
      top: "50%",
      left: "0%",
      width: "100%",
      height: "auto",
      opacity: "0",
      transition: "all 0.5s ease",
    },
    {
      top: "50%",
      left: "0%",
      padding: "50px 0px",
      opacity: "1",
      transition: "all 0.5s ease",
    },
  ],
  backgroundImage: null,
  iframeSrc: null,
  animationBackground: [
    {
      minHeight: "600px",
      opacity: 0.5,
      background:
        "linear-gradient(120deg, rgb(114, 165, 249) 0%, rgb(141, 215, 249) 100%)",
      transition: "all 0.5s 0.8s ease",
      border: "none",
    },
    {
      minHeight: "600px",
      opacity: 1,
      background:
        "linear-gradient(120deg, rgb(114, 165, 249) 0%, rgb(141, 215, 249) 100%)",
      transition: "all 0.5s 0.8s ease",
      border: "none",
    },
  ],
  minHeight: 600,
  paralax: 0,
}

export default Section
